import React from "react";
import { Container } from "react-bootstrap";
import { Button } from "react-bootstrap";

export default function EstimateHome() {
  return (
    <div>
      <div className="estimate-page">
        <div className="estimate-banner">
          <Container>
            <div className="row">
              <div className="banner-heading">
                <div>
                  <div className="subheading">
                    Build accurate project estimates and
                  </div>
                  <h2>Make promises you can keep</h2>
                  <p>
                    Set yourself for success with word’s best estimation tool.
                  </p>
                  <Button className="signup-btn">
                    <a href="/signup">Sign up</a>
                  </Button>
                </div>
              </div>
              <div className="banner-img">
                <img src="img/landing-page-banner.png" alt="GATEZERO"></img>
              </div>
            </div>
          </Container>
          <div className="round"></div>
          <div className="round-small"></div>

          <div className="estimate-bg">
            <img src="img/estimate-bg.png" alt="GATEZERO"></img>
          </div>
        </div>
        <div className="estimate-services">
          <Container>
            <div className="row">
              <div className="services-grid">
                <div className="service-icon">
                  <img src="img/estimate-icon-1.png" alt="GATEZERO" />
                </div>
                <p>
                  Over 80% of IT projects are
                  <br />
                  marked late
                </p>
              </div>
              <div className="services-grid">
                <div className="service-icon">
                  <img src="img/estimate-icon-2.png" alt="GATEZERO" />
                </div>
                <p>
                  Inaccurate estimates are a big
                  <br />
                  part of project’s being late
                </p>
              </div>
              <div className="services-grid">
                <div className="service-icon">
                  <img src="img/estimate-icon-3.png" alt="GATEZERO" />
                </div>
                <p>
                  You need a comprehensive tool to
                  <br />
                  reveal real cost and time to{" "}
                </p>
              </div>
            </div>
          </Container>
        </div>

        <div className="services-content-with-trail">
          <div className="outer-box"></div>
          <div className="outer-box"></div>

          <Container>
            <div className="row">
              <div className="left-service-content">
                <ul>
                  <li>How much will your product cost?</li>
                  <li>When will you release it?</li>
                  <li>What kind of team would you need?</li>
                  <li>What are the timelines?</li>
                </ul>
              </div>
              <div className="service-trial-content">
                <h4>Free 7 Day Trial</h4>
                <p>Watch 3 min video</p>
                <Button className="signup-btn">
                  <a href="/signup">Sign up</a>
                </Button>
              </div>
            </div>
          </Container>
        </div>

        <div className="service-summary">
          <Container>
            <div className="row">
              <div className="service-summary-content">
                <div className="sub-heading">
                  We set out with only one mission.
                </div>
                <div className="main-heading">
                  CREATE BEST ESTIMATION TOOL IN THE WORLD.
                </div>
                <div className="heading">
                  Easy to use, fast, accurate and safe
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className="team-time-cost-summary">
          <Container>
            <div className="row">
              <div className="team-summary">
                <div className="top-text">
                  <h4>Build accurate time and cost</h4>
                  <h1>State-of-the-art timeline tool</h1>
                  <p>Collaborate with other team members</p>
                </div>
                <div className="bottom-text">
                  <h3>Team formulation with headcount and skills</h3>
                  <p>
                    Full estimates across product development, sales and
                    marketing, legal, architecture,
                    <br></br>
                    finance, management, and add anything custom you need.
                  </p>
                </div>
              </div>
              <div className="team-image">
                <img src="img/estimate-img.png" alt="time-cost" />
              </div>
            </div>
          </Container>
        </div>

        <div className="subscribe-container">
          <Container>
            <div className="row">
              <h2>
                Build a comprehensive estimate or
                <br />a ballpark - Your choice.
              </h2>
              <div className="sign-up-container">
                <div className="price-grid">
                  <div className="plan-heading white-bg"></div>
                  <div className="price"></div>
                  <div className="user-month">FREE 7 DAY TRIAL</div>
                  <div className="course-content">
                    <p>Watch 3 min video</p>
                  </div>
                  <Button className="subscribe-signup-btn">
                    <a href="/signup">Sign up</a>
                  </Button>
                </div>

                <div className="price-grid">
                  <div className="plan-heading">BASIC</div>
                  <div className="price">$ 4.99</div>
                  <div className="user-month">USER / MONTH</div>
                  <div className="course-content">
                    <p>Time & Cost Estimates </p>
                  </div>
                  <Button className="subscribe-signup-btn">
                    <a href="/signup">Sign up</a>
                  </Button>
                </div>

                <div className="price-grid">
                  <div className="plan-heading">BETTER</div>
                  <div className="price">$ 7.99</div>
                  <div className="user-month">USER / MONTH</div>
                  <div className="course-content">
                    <p>Time & Cost Estimates</p>
                    <p>Team Formulation</p>
                  </div>
                  <Button className="subscribe-signup-btn">
                    <a href="/signup">Sign up</a>
                  </Button>
                </div>

                <div className="price-grid">
                  <div className="plan-heading">BEST</div>
                  <div className="price">$ 9.99</div>
                  <div className="user-month">USER / MONTH</div>
                  <div className="course-content">
                    <p>Time & Cost Estimates</p>
                    <p>Team Formulation</p>
                    <p>Timeline View</p>
                  </div>
                  <Button className="subscribe-signup-btn">
                    <a href="/signup">Sign up</a>
                  </Button>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className="review-section">
          <Container>
            <div className="row">
            <div className="review-wrap">
              <h2>User Reviews</h2>
              <div className="client-review">
                <h3>Great tool</h3>
                <div className="rating">
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                </div>
                <p>
                  I recently started using this tool to estimate all my project
                  and incremental change work, it’s very comprehensive and makes
                  it really easy to show the time, cost and get financial
                  approvals
                </p>
              </div>
              <div className="client-review">
                <h3>Superb timeline tool</h3>
                <div className="rating">
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                </div>
                <p>
                  Blown away by the timeline tool, the best and most useful.
                </p>
              </div>
              <div className="client-review">
                <h3>Let's me see the full cost</h3>
                <div className="rating">
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                </div>
                <p>
                  As a owner of software company, this tool lets me build the full
                  cost of running a product, not just the development cost. This is a real good tool.
                </p>
              </div>
              <div className="client-review">
                <h3>Easy to use</h3>
                <div className="rating">
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                </div>
                <p>
                  You can start with a quick estimate or build a detailed one, timeline tool is great.
                </p>
              </div>
              <div className="client-review">
                <h3>Love the collaboration feature</h3>
                <div className="rating">
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                </div>
                <p>
                  I am able to collaborate with various groups to build a comprehensive
                  estimate that my finance folks can then approve, I don't have to go through
                  the whole approval cycle as I generally had to do in past. Great tool.
                </p>
              </div>
              <div className="client-review">
                <h3>Team, Skills, Hours, Cost, Timeline</h3>
                <div className="rating">
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                </div>
                <p>
                  Easy, let's me build my team, skills, hours, cost and timeline. What more do you need?
                </p>
              </div>
              <div className="client-review">
                <h3>Boon to prepare my executive deck</h3>
                <div className="rating">
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                </div>
                <p>
                  I am responsible to presenting status of many projects to my executive team, this
                  tools have made my life simple, I can quickly build timeline view, color code it,
                  put comments, grab a screen shot and put in it mu poweroint. Life made easy :-)
                </p>
              </div>
              <div className="client-review">
                <h3>Save me a ton of time</h3>
                <div className="rating">
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                </div>
                <p>
                  Easy time line tool helps me save so much time
                </p>
              </div>
              <div className="client-review">
                <h3>Easy for making hiring requests</h3>
                <div className="rating">
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                </div>
                <p>
                  Apart of other obvious uses, I like how it allows me to build an exact
                  skill map, that I can just use to request resources from my HR or other teams.
                  Great features, and very simple and easy to use.
                </p>
              </div>
              <div className="client-review">
                <h3>Everyone is using this now</h3>
                <div className="rating">
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                </div>
                <p>
                  I started using this tool a couple of months back and now my entire team,
                  along with sales, marketing, finance started using this. Quickly became popular in my company.
                </p>
              </div>
              <div className="client-review">
                <h3>Helps me avoid losses</h3>
                <div className="rating">
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                  <i className="star active"></i>
                </div>
                <p>
                  We run a consulting firm, sometimes our clients asks for fixed bid engagement, this tool is great for
                  getting to the real costs. Highly recommend this to any IT project estimation work.
                </p>
              </div>
              </div>

            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}
