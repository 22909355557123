import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { checkoutForm } from "../service/auth";

export default function PaypalExpressBtn(props) {
  const navigate = useNavigate();
  const [planID, setPlanID] = useState("");
  const [token, setToken] = useState("");
  const estimationurl = process.env.REACT_APP_BASE_ESTIMATION_URL

  if (!token) {
    let token = localStorage.getItem("token")
    setToken(token)
  }

  const onApprove = async (subscriptionID, orderID) => {
    let propsdata = props.udata
    let planData = props.planData

    let newdata = { ...propsdata, ...{ subscriptionID, orderID } }

    let result = await checkoutForm(newdata.firstname, newdata.lastname, newdata.email, newdata.address, newdata.phone, newdata.city, newdata.country, newdata.from, newdata.zip, newdata.u_email, newdata.packType, newdata.subscriptionID, newdata.orderID, planData)
    if (props.planData.PackageType == "estimation") {
      navigate("/user")
      window.open(estimationurl + token, "_blank")
    } else {
      navigate("/user")
    }
  }

  useEffect(() => {
    // alert(JSON.stringify(props.planData));
    window.paypal.Buttons({
      style: {
        shape: 'rect',
        color: 'gold',
        layout: 'vertical',
        label: 'subscribe'
      },
      createSubscription: function (data, actions) {

        return actions.subscription.create({
          plan_id: props.planData.PayPalPlanID
        });
      },
      onApprove: function (data, actions) {
        onApprove(data.subscriptionID, data.orderID)
          ;
      }
    }).render('#paypal-button-container');

  }, []);
  console.log('planID' + planID);

}