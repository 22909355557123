import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Layout2 from "./Admin/Layout2";
import Layout from "./Frontend/Layout";
import Admindashboard from "./Admin/Admindashboard";
import "./Frontend/style.css";
import "./Frontend/scss/app.css";
import InovativeBoard from "./Admin/Component/InovationBoard";
import Lead from "./Admin/Lead";
import AOS from "aos";
import "aos/dist/aos.css";
import '../src/style.css'
const App = () => {

  useEffect(() => {
    AOS.init({
      startEvent: 'load',
    });
    AOS.refresh()
  }, []);
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Layout />} />
        <Route exact path="/*" element={<Layout />} />
        <Route exact path="/user/*" element={<Layout2 />} />
        <Route exact path="/admin" element={<Admindashboard />} />
        <Route exact path='/landing' element={<Lead />} />
        <Route exact path="/user/innovationboard" element={<InovativeBoard />} />
        <Route exact path="/user/innovationboard/:id" element={<InovativeBoard />} />
      </Routes>
    </>
  );
};

export default App;
