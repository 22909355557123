import React from "react";
import { Container, Row } from "react-bootstrap";

const Footer = () => {

    const getYear = () => {
        return new Date().getFullYear()
    }

    return (
        <>
            <div className="footer">
                <Container>
                    <Row>
                        <p>Copyright © 2022-{getYear()} Paybacck - All Rights Reserved.</p>
                    </Row>
                </Container>
            </div>
        </>
    );
}
 
export default Footer;