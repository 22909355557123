import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {
  checkIfAssigned,
  delinkAssigned,
  getAssigned,
  getFreetrail,
  getPackages,
  getPaymentsHistory
} from "../../service/auth";
import Alert from "react-bootstrap/Alert";
import dateFormat from "dateformat";
import { ContactSupportOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Link, Route, Routes } from "react-router-dom";
import ManageSubscription from "../../Frontend/ManageSubscription";

export default function Packages() {

  const user_email = localStorage.getItem("email");
  const [result, setResult] = useState(false);
  const [checkAssignedUsers, setCheckAssignedUsers] = useState(false);
  const [token, setToken] = useState("");
  const [created_date, setCreateddate] = useState("");
  const [showAlert, setAlert] = useState(false);
  const [showAssignedtable, setAssignedtable] = useState(false);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [showAlertMsg, setAlertMsg] = useState({ type: "", msg: "" });
  const [mainName, setmainUser] = useState("");

  const [gateZero, setGateZero] = useState([]);
  const [estimation, setEstimation] = useState([]);

  const [oldSub, setOldSubscription] = useState([]);

  const [showEstimation, setShowEstimation] = useState(false);
  const [showGateZero, setShowGateZero] = useState(false);

  const [gateZeroExpire, setGateZeroExpire] = useState(false);
  const [estimationExpire, setEstimationExpire] = useState(false);

  const [gatezeroStatus, setGatezeroStatus] = useState(true);
  const [estimationStatus, setEstimationStatus] = useState(true);

  const [purchasedPackages, setPurchasedPackages] = useState([]);
  const [gatezeroPaid, setGateZeroPaid] = useState(false);
  const [estimationPaid, setEstimationPaid] = useState(false);
  const [videotrainingPaid, setvideoTrainingPaid] = useState(false);
  const [personaltrainingpaid, setPersonalTrainingPaid] = useState(false);

  const navigate = useNavigate();

  function AlertShow(props) {
    if (showAlert) {
      return (
        <Alert variant={props.type} dismissible onClose={() => { setAlert(false); setAlertMsg({ type: "", msg: "" }) }}>
          <Alert.Heading>Alert !</Alert.Heading>
          <p>{props.text}</p>
        </Alert>
      );
    }
  }

  const clickDelink = async (user_email, token, email) => {

    setAlert(false);
    let delink = await delinkAssigned(user_email, token, email);
    //alert(delink.data.success)
    if (delink.data.success) {
      setAlertMsg({
        type: "success",
        msg: "User has been delinked successfully",
      });
      setAlert(true);

      componentDidMount();
    } else {
      setAlertMsg({
        type: "danger",
        msg: "Some error occured please try again!",
      });
      setAlert(true);

    }
  };

  const componentDidMount = async () => {

    let assignedUsers = await getAssigned(user_email);
    console.log(assignedUsers.data.data)
    if (assignedUsers.data.success) {
      setAssignedUsers(assignedUsers?.data?.data || []);
    }

    let getOldSubscription = await getPaymentsHistory('me');

    if (getOldSubscription.data.success) {
      setOldSubscription(getOldSubscription.data.data)
    }

    let result = await getPackages(user_email);
    setCreateddate(dateFormat(result.data.created_at, "mm/dd/yyyy"));

    if (result.data.package_data.length) {
      setResult(true);
      setPurchasedPackages(result.data.package_data)

      // Gatezero Data
      result.data.package_data.map((item) => {
        if (item.type === "gatezero") {
          setGateZeroPaid(true);
          if (item.package_limit > "1" || item.package_limit == "-1") {
            if (assignedUsers.data.data.length <= 0) {
              setAssignedtable(false);
            } else {
              setAssignedtable(true);
            }
          }
          else {
            setAssignedtable(false);
          }
          setToken(item.package_token);

        }
        if (item.type === "estimation") {
          setEstimationPaid(true);
        }
        if (item.type === "videotraining") {
          setvideoTrainingPaid(true);
        }
        if (item.type === "personaltraining") {
          setPersonalTrainingPaid(true);
        }
      });


      // let estimationdata_free = result.data.package_data.filter((item) => {
      //   if (item.type === "estimation_free") {
      //     if (item.days_left > 0) {
      //       setShowEstimation(true);
      //       setEstimationExpire(false);

      //     } else {
      //       setEstimationExpire(true);
      //       setShowEstimation(false);
      //     }
      //     console.log(item)
      //     setEstimation([item]);
      //   }
      // });

      // let gatezero_free = result.data.package_data.filter((item) => {
      //   if (item.type === "gatezero_free") {
      //     if (item.days_left > 0) {
      //       setShowGateZero(true);
      //       setGateZeroExpire(false);

      //     } else {
      //       setGateZeroExpire(true);
      //       setShowGateZero(false);
      //     }
      //     setGateZero([item]);
      //   }
      // });

      // // Gatezero Data
      // result.data.package_data.filter((item) => {
      //   if (item.type === "gatezero") {
      //     if (item.days_left > 0) {
      //       setShowGateZero(true);
      //       setGateZeroExpire(false);
      //       if (item.package_limit > "1" || item.package_limit == "-1") {
      //         if (assignedUsers.data.data.length <= 0) {
      //           setAssignedtable(false);
      //         } else {
      //           setAssignedtable(true);
      //         }
      //       }
      //       else {
      //         setAssignedtable(false);
      //       }
      //     } else {
      //       setGateZeroExpire(true);
      //       setShowGateZero(false);
      //     }
      //     setToken(item.package_token);
      //     console.log([item])
      //     setGateZero([item]);
      //   }
      // });

      // // Estimation Data

      // result.data.package_data.filter((item) => {
      //   if (item.type === "estimation") {
      //     if (item.days_left > 0) {
      //       setShowEstimation(true);
      //       setEstimationExpire(false);
      //     } else {
      //       setShowEstimation(false);
      //       setEstimationExpire(true);
      //     }
      //     console.log([item])
      //     setEstimation([item]);
      //   }
      // });

      // result.data.package_data.filter((item) => {
      //   if (item.type === "videotraining") {
      //     if (item.days_left > 0) {
      //       setShowVideoTraining(true);
      //       setVideoExpire(false);
      //     } else {
      //       setShowVideoTraining(false);
      //       setVideoExpire(true);
      //     }
      //     console.log([item])
      //     setVideoTraining([item]);
      //   }
      // });

      let checkassigned = await checkIfAssigned(user_email);

      if (!checkassigned.data.error) {
        setCheckAssignedUsers(true);
        setmainUser(checkassigned.data.name);
      }
    }
  };

  const packagepage = () => {
    window.location.reload();
  };
  useEffect(() => {
    componentDidMount();
  }, []);

  function capitalizeFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }


  const type = (val) => {

    if (val == 'estimation') return "Estimation"
    if (val == 'gatezero') return "Paybacck"
    if (val == 'gatezero_free') return "Paybacck free"
    if (val == 'estimation_free') return "Estimation free"
    else {
      return val
    }
  }


  if (!result) {
    return (
      <>
        <ManageSubscription />
        <div>
          <div className="dashbord-page">
            <div className="container">
              <h1>Packages</h1>
              {checkAssignedUsers == true ? (
                <div>
                  <Card body className="no-package">
                    You have been added to paid package by {mainName}.
                  </Card>
                </div>
              ) : (
                <div>
                  <Card body className="no-package">
                    No Package has been purchased yet.
                  </Card>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div>
        <ManageSubscription />

        <div className="dashbord-page">
          <div className="container">
            {purchasedPackages.map((e) => {
              let type = capitalizeFirstLetter(e.type)
              if (type.includes("_")) {
                let word = type.split("_")
                type = word[0]
              }
              if (e.type == "gatezero_free" && gatezeroPaid) {
                return
              }
              if (e.type == 'estimation_free' && estimationPaid) {
                return
              }
              if (e.type == 'videotraining_free' && videotrainingPaid) {
                return
              }
              if (e.type == 'personaltraining_free' && personaltrainingpaid) {
                return
              }

              return (
                <>
                  <h1>{type}</h1>
                  {e.days_left > 0 ? < Card body className="package">
                    <div className="row">
                      {type == "Gatezero" && <div className="col-md-6">
                        <h6>
                          Package Type:
                          <span>
                            {e.package_limit == '-1' ? "Unlimited" : e.package_limit}
                          </span>
                        </h6>
                      </div>}
                      {type == "Gatezero" && <div className="col-md-6">
                        <h6>
                          Package Limit:
                          <span>
                            {e.product_package?.IsFreeTrial == "1" ? e.product_package?.FreeTrialPeriodInDays + ' days' : " 1 Month"}
                          </span>
                        </h6>
                      </div>}
                      {type == "Gatezero" && <div className="col-md-6">
                        <h6>
                          Allowed Scorecard:
                          <span>
                            {e.scorecard_limit == '-1' ? "Unlimited" : e.scorecard_limit}
                          </span>
                        </h6>
                      </div>}
                      <div className="col-md-6">
                        <h6>
                          Package Token: <span>{e.package_token}</span>
                        </h6>
                      </div>
                      <div className="col-md-6">
                        <h6>
                          Purchased Date: <span>{created_date}</span>
                        </h6>
                      </div>
                      <div className="col-md-6">
                        <h6>
                          Description: <span>{e?.product_package?.PackageDescription || ""}</span>
                        </h6>
                      </div>
                    </div>
                  </Card >
                    :
                    <Card body className="package">
                      <div className="row">
                        <div className="col-md-6">
                          <h6>Subscription has been ended.</h6>
                          <Button variant="dark" size="lg" onClick={packagepage}>
                            Subscribe
                          </Button>
                        </div>
                      </div>
                    </Card>
                  }
                </>
              )
            })}

            {/* {showGateZero ? (
              <>
                <h1>GateZero Packages</h1>
                <Card body className="package">
                  <div className="row">
                    <div className="col-md-6">
                      <h6>
                        Package Type:
                        <span>
                          {gateZero[0].package_limit == '-1' ? "Unlimited" : gateZero[0].package_limit}
                        </span>
                      </h6>
                    </div>
                    <div className="col-md-6">
                      <h6>
                        Package Limit:
                        <span>
                          {gateZero[0].product_package?.IsFreeTrial == "1" ? gateZero[0].product_package?.FreeTrialPeriodInDays + ' days' : " 1 Month"}
                        </span>
                      </h6>
                    </div>
                    <div className="col-md-6">
                      <h6>
                        Allowed Scorecard:
                        <span>
                          {gateZero[0].scorecard_limit == '-1' ? "Unlimited" : gateZero[0].scorecard_limit}
                        </span>
                      </h6>
                    </div>
                    <div className="col-md-6">
                      <h6>
                        Package Token: <span>{gateZero[0].package_token}</span>
                      </h6>
                    </div>
                    <div className="col-md-6">
                      <h6>
                        Purchased Date: <span>{created_date}</span>
                      </h6>
                    </div>
                    <div className="col-md-6">
                      <h6>
                        Description: <span>{gateZero[0]?.product_package?.PackageDescription || ""}</span>
                      </h6>
                    </div>
                  </div>
                </Card>
              </>
            ) : gateZeroExpire ? (
              <>
                <h1>GateZero Packages</h1>
                <Card body className="package">
                  <div className="row">
                    <div className="col-md-6">
                      <h6>Subscription has been ended.</h6>
                      <Button variant="dark" size="lg" onClick={packagepage}>
                        Subscribe
                      </Button>
                    </div>
                  </div>
                </Card>
              </>
            ) : (
              <></>
            )}
            {showEstimation ? (
              <>
                {" "}
                <h1>Estimation Packages</h1>
                <Card body className="package">
                  <div className="row">
                    {estimation[0]?.features_data?.map((item, i) => {
                      return (
                        <div className="col-md-6" key={i}>
                          <h6>
                            {item + ":"} <span> {"Allowed"}</span>
                          </h6>
                        </div>
                      );
                    })}
                    <div className="col-md-6">
                      <h6>
                        Package Token:{" "}
                        <span>{estimation[0].package_token}</span>
                      </h6>
                    </div>
                    <div className="col-md-6">
                      <h6>
                        Package Limit:{" "}
                        <span>
                          {estimation[0].product_package?.IsFreeTrial == "1" ? estimation[0].product_package?.FreeTrialPeriodInDays + ' days' : " 1 Month"}
                        </span>
                      </h6>
                    </div>
                    <div className="col-md-6">
                      <h6>
                        Purchased Date: <span>{created_date}</span>
                      </h6>
                    </div>
                    <div className="col-md-6">
                      <h6>
                        Description: <span>{estimation[0]?.product_package?.PackageDescription || ""}</span>
                      </h6>
                    </div>
                  </div>
                </Card>
              </>
            ) : estimationExpire ? (
              <>
                <h1>Estimation Packages</h1>
                <Card body className="package">
                  <div className="row">
                    <div className="col-md-6">
                      <h6>Subscription has been ended.</h6>
                      <Button variant="dark" size="lg" onClick={packagepage}>
                        Subscribe
                      </Button>
                    </div>
                  </div>
                </Card>
              </>
            ) : (
              <></>
            )} */}
            {showAssignedtable && (
              <div className="assigned-user">
                <h1>Assigned Users</h1>
                <AlertShow type={showAlertMsg.type} text={showAlertMsg.msg} />
                <table
                  id="assigned_user"
                  className="table table-hover table-bordered assigned-table"
                >
                  <thead>
                    <tr>
                      <th>User Name</th>
                      <th>User Email</th>
                      <th>User Role</th>
                      <th>Delink User</th>
                    </tr>
                  </thead>
                  <tbody>
                    {assignedUsers.map((result, i) => {
                      return (
                        <tr key={i}>
                          <td>{result.name}</td>
                          <td>{result.email}</td>
                          <td>{result.role}</td>
                          <td>
                            <button
                              disabled={user_email == result.email}
                              style={{ color: user_email == result.email ? 'lightgray' : 'dd' }}
                              onClick={() =>
                                clickDelink(user_email, token, result.email)
                              }
                            >
                              Delink User
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
            {oldSub.length > 0 && <div className="user-subscriptions">
              <h2 style={{ color: 'black', margin: "15px 0px" }}>Subscriptions History</h2>
              <table
                id=""
                className="table table-hover table-bordered assigned-table"
              >
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Paid time</th>
                    <th>Start date</th>
                    <th>End date</th>
                    <th>Price</th>
                    {/* <th>Package Token</th> */}
                    <th>Subscription ID</th>
                  </tr>
                </thead>
                <tbody>
                  {oldSub.map((result) => {
                    return (
                      <tr>
                        <td>{type(result.type)}</td>
                        <td>{dateFormat(result?.PaidTime || '', 'dd/mm/yy HH:MM:ss')}</td>
                        <td>{dateFormat(result?.StartTime || '', 'dd/mm/yy HH:MM')}</td>
                        <td>{result?.subscription_ending_at}</td>
                        <td>{result?.product_package?.CostInUSD || 'Free'}</td>
                        <td>{result.SubscriptionID}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>}
          </div>
        </div>
      </div >
    );
  }
}
