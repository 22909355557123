import { Container, Form, Row, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header2 from "./Header2";
import Initiativeheader from "../Component/Initiativeheader";
import { useSelector, useDispatch } from "react-redux";
import { setProblemScoreCard, setRevenuescore } from "../../Reducer/reducer";
import ReactLoading from "react-loading";
import { setJson, getJson } from "../../service/auth";
import VideoContainer from "../../Frontend/Component/VideoContainer";
import ToolKit from "../../Frontend/Component/ToolKit";

export default function Problemscorecard() {
  let loader;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let evaluation_id = localStorage.getItem("evaluation_id");
  const [issubmit, setSubmit] = useState(false);

  const screenWidth = window.innerWidth

  const [motivationscore, setMotivationScore] = useState("");
  const [marketsize, setMarketSize] = useState("");
  const [problemfrequency, setProblemFrequency] = useState("");
  const [timesaving, setTimeSaving] = useState("");
  const [lifeproblem, setLifeProblem] = useState("");
  const [lifesaving, setLifeSaving] = useState("");
  const [conveniencecomfort, setConvenienceComfort] = useState("");
  const [regulatory, setRegulatory] = useState("");
  const [security, setSecurity] = useState("");
  const [revenueopp, setRevenueopp] = useState("");
  const [event, setEvent] = useState("");
  const [eventFrequency, setEventFrequency] = useState("");
  const [motivationTransition, setMotivationTransition] = useState("");
  const [total, setTotal] = useState("");


  const [pv, setProblemValidation] = useState(0)
  const [bm, setbuyermotivation] = useState(0)
  // const [psms , setProblemScoreCardMarketsize] = useState(0)
  const [ca, setCruxalignment] = useState(0)
  const [sc, setSolutionscore] = useState(0)
  // const [psf , setProblemScoreCardFrequency] = useState(0)
  const [r, setRevenue] = useState(0)
  const [sr, setSolutionriskscore] = useState(0)
  const [other, setpmcmother] = useState(0)

  const scoredata = useSelector((state) => state.scorereducer.scorevalue)

  const getpmcm = () => {
    getJson(evaluation_id).then((res) => {
      let data = JSON.parse(res.data.data.json);
      if (data) {
        if (data.buyermotivation.score >= 2) {
          setbuyermotivation(1);
        } else {
          setbuyermotivation(-1)
        }

        if (data.problemvalidationscore.alltotal > 0) {
          setProblemValidation(2);
        } else {
          setProblemValidation(-3);
        }

        // if (
        //   data.problemscorecard.marketsize == 0 ||
        //   data.problemscorecard.marketsize == 5
        // ) {
        //   setProblemScoreCardMarketsize(1);
        // } else {
        //   setProblemScoreCardMarketsize(-2);
        // }

        if (data.cruxalignment.total >= 25) {
          setCruxalignment(4);
        } else {
          setCruxalignment(-2);
        }

        if (data.solutionscorecard.total >= 10) {
          setSolutionscore(2);
        } else {
          setSolutionscore(-10);
        }

        // if (data.problemscorecard.problemfrequency == 3) {
        //   setProblemScoreCardFrequency(2);
        // } else {
        //   setProblemScoreCardFrequency(-3);
        // }

        if (data.revenuescore.total <= 5) {
          setRevenue(-3);
        } else {
          setRevenue(1);
        }

        if (data.solutionriskscore.total == 0) {
          setSolutionriskscore(0);
        } else {
          setSolutionriskscore(-1);
        }

        // console.log(pmcmtotal)

        setpmcmother(
          data.pmcmalignment.businessgoals + data.pmcmalignment.interfaceoption
        );
      }
    });
  };

  const setRedux = (data, json, evaluation_id) => {
    dispatch(
      setProblemScoreCard({
        data,
        json,
        evaluation_id,
      })
    );
    setJson(json, evaluation_id);
  };

  const totalData = (data) => {
    let sum = 0;
    for (let [key, val] of Object.entries(data)) {
      if (key != "total") {
        sum += val ? val : 0;
      }
    }
    return parseInt(sum);
  };

  let data = {
    motivationscore,
    marketsize,
    problemfrequency,
    timesaving,
    lifeproblem,
    lifesaving,
    conveniencecomfort,
    regulatory,
    security,
    revenueopp,
    event,
    eventFrequency,
    motivationTransition,
    total,
  };

  let problemscore = (score) => {
    if (score >= 10) {
      return 2;
    } else {
      return -5;
    }
  };
  let problemscorefre = (score) => {
    if (score == 3) {
      return 2;
    } else {
      return -3;
    }
  };
  let problemscoremarket = (score) => {
    if (score == 0 || score == 5) {
      return 1;
    } else {
      return -2;
    }
  };

  let pmcm = (score) => {
    getJson(evaluation_id).then((res) => {
      let fetcheddata = JSON.parse(res.data.data.json);
      // console.log(fetcheddata);
      // console.log("fetcheddata");
      if (fetcheddata) {

        let problemscoreval = problemscore(score);
        let problemscorefreval = problemscorefre(problemfrequency);
        let problemscoremarketval = problemscoremarket(marketsize);

        let finaldata = parseInt(pv + bm + ca + sc + r + sr + other) + parseInt(problemscoreval) + parseInt(problemscorefreval) + parseInt(problemscoremarketval)
        console.log(parseInt(problemscoreval) + parseInt(problemscorefreval) + parseInt(problemscoremarketval))
        // console.log(buyerdata)
        setJson(JSON.stringify({ pmcmalignment: { ...fetcheddata.pmcmalignment, total: parseInt(finaldata) } }), evaluation_id)

        navigate("/user/problemvalidation");

      }
    });
  };

  const backButton = (event) => {
    if (!scoredata) {
      event.preventDefault();
      event.stopPropagation();
      let score = totalData(data)
      setRedux(
        data,
        JSON.stringify({ problemscorecard: { ...data, total: score } }),
        evaluation_id
      );
      setTotal(totalData(data));
      navigate(-1)
    } else {
      navigate(-1);
    }
  };

  const submitData = (event) => {
    if (!scoredata) {
      event.preventDefault();
      event.stopPropagation();
      let score = totalData(data)
      setRedux(
        data,
        JSON.stringify({ problemscorecard: { ...data, total: score } }),
        evaluation_id
      );
      setTotal(totalData(data));



      pmcm(score);
    } else {
      navigate("/user/problemvalidation");
    }
  };

  useEffect(() => {
    setTotal(totalData({ ...data }));
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setSubmit(true);
    getJson(evaluation_id).then((res) => {
      let data = JSON.parse(res.data.data.json);
      if (data.buyermotivation) {
        setMotivationScore(data.buyermotivation.score);
      }
      if (data.problemscorecard) {
        setMarketSize(data.problemscorecard.marketsize);
        setProblemFrequency(data.problemscorecard.problemfrequency);
        setTimeSaving(data.problemscorecard.timesaving);
        setLifeProblem(data.problemscorecard.lifeproblem);
        setLifeSaving(data.problemscorecard.lifesaving);
        setConvenienceComfort(data.problemscorecard.conveniencecomfort);
        setRegulatory(data.problemscorecard.regulatory);
        setSecurity(data.problemscorecard.security);
        setRevenueopp(data.problemscorecard.revenueopp);
        setMotivationTransition(data.problemscorecard.motivationTransition);
        setEvent(data.problemscorecard.event);
        setEventFrequency(data.problemscorecard.eventFrequency);
        setTotal(totalData(data.problemscorecard));
      }
    }).finally(() => {
      setSubmit(false);
    })
  }, []);

  useEffect(() => {
    getpmcm();
  }, [
    motivationscore,
    security,
    motivationTransition,
    total,
    revenueopp,
    eventFrequency,
    event,
    marketsize,
    problemfrequency,
    timesaving,
    lifeproblem,
    lifesaving,
    conveniencecomfort,
    regulatory,
  ]);

  const handleScroll = (event) => {
    event.preventDefault();

    window.scrollTo(0, document.getElementById('videos_title').getBoundingClientRect().top - 70)
  }

  if (issubmit) {
    loader = <ReactLoading type="bubbles" color="#162c5d" width={100} />;
  }

  return (
    <div>
      {" "}
      <Header2 />
      <div className="page-wraper">
        <div className="Probdesc-page">
          <Initiativeheader eid={evaluation_id} />
          <Container>
            <Row>
              {/* <div className="col-md-12">
                    
                    <div className="next-back-button">{loader}
                      <Button
                        variant="primary button-green"
                        onClick={() => navigate(-1)}
                      >
                        Back
                      </Button>
                      <Button
                        variant="primary button-blue"
                        onClick={(e) => submitData(e)}
                      >
                        Next
                      </Button>
                    </div>
                  </div> */}
              <div className="dimentional-title">
                <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                  <h2 style={{ marginRight: '40px' }}>
                    Problem Scorecard
                  </h2>
                  <div style={{ marginBottom: '18px', width: '280px', display: 'flex', justifyContent: 'flex-end' }}><a onClick={handleScroll} href="" >Watch training videos - how it works</a></div>
                </div>
                {loader}
                <p>
                  The problem score inform you of the strength of the problem
                  you are trying to solve? It has a direct relation with your
                  ability to monetize your solution. The overall problem score
                  is seen with respect to the kind of funding and other
                  resources you have.
                </p>
              </div>
              <div className="problem-form">
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group
                      className="form-group"
                      controlId="formBasicbuyar"
                    >
                      <Form.Label>Buyer Motivation Parity Score</Form.Label>
                      <Form.Control
                        type="text"
                        readOnly
                        value={motivationscore}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group
                      className="form-group"
                      controlId="formGridState"
                    >
                      <Form.Label>Market Size</Form.Label>
                      <ToolKit text={'Select the size of the market experiencing the problem that your solution intends to solve. If you are making a solution for airline manufacturers, you will select “A few customers”; if you are making a solution for companies to control their cloud computing cost, you may select “000s” as an option, and if you are providing a solution for an after-market product to help drivers you can select “Millions” as an option. Remember, this is the size of the market; this does not indicate how many customers you would acquire.'} to={screenWidth < 640 ? "center" : 'right'} />

                      <Form.Select
                        disabled={scoredata ? true : false}
                        value={marketsize}
                        onChange={(e) => {
                          setMarketSize(parseInt(e.target.value));
                        }}
                      >
                        <option value="">-- Select One --</option>
                        <option value="-4">Few Customers</option>
                        <option value="0">000's Thousands</option>
                        <option value="5">Millions</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group
                      className="form-group"
                      controlId="formGridState"
                    >
                      <Form.Label>Frequency of the Problem</Form.Label>
                      <ToolKit text={`
                      How often does the customer experience the problem? Low, Med, and High are relative selections based on the occurrence of the problem while performing the activity in which the problem happens. This is selected based on the majority of the customers.
                      Here are the general guidelines for selection.</br>
                      <strong>Low:</strong> The problem is experienced occasionally or rarely. (Someone drowning in a swimming pool, filing taxes)</br>
                      <strong>Med:</strong> The problem is experienced at a regular interval. (monthly compliance reports)</br>
                      <strong>High:</strong> The problem is experienced all the time. (Infrastructure monitoring)`} to={screenWidth < 400 ? "left" : screenWidth < 640 ? "center" : 'right'} />

                      <Form.Select
                        disabled={scoredata ? true : false}
                        value={problemfrequency}
                        onChange={(e) => {
                          setProblemFrequency(parseInt(e.target.value));
                        }}
                      >
                        <option value="">-- Select One --</option>
                        <option value="1">Low</option>
                        <option value="2">Med</option>
                        <option value="3">High</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group
                      className="form-group"
                      controlId="formGridState"
                    >
                      <Form.Label>Time saving Opportunity</Form.Label>
                      <ToolKit text={'Select if your solution can save time and in what order it cuts down the time required to perform the activity for which you are providing a solution by 2x,4x,…. Or by order of 20x, 30x…or 100x. '} to={screenWidth < 400 ? "left" : screenWidth < 640 ? "center" : 'right'} />

                      <Form.Select
                        disabled={scoredata ? true : false}
                        value={timesaving}
                        onChange={(e) => {
                          setTimeSaving(parseInt(e.target.value));
                        }}
                      >
                        <option value="">-- Select One --</option>
                        <option value="1">1x</option>
                        <option value="2">10x</option>
                        <option value="4">100x</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group
                      className="form-group"
                      controlId="formGridState"
                    >
                      <Form.Label>Life of a Problem</Form.Label>
                      <ToolKit text={'Life of the problem is about how long you expect the problem to continue to last. Some issues are temporary, and go away in short while, others are perpetual. For e.g. needing a solution for sales and marketing is a problem with years of life, whereas solutioning for Summer Olympic for a country might only have months’ worth of life.'} to={screenWidth < 640 ? "center" : 'right'} />

                      <Form.Select
                        disabled={scoredata ? true : false}
                        value={lifeproblem}
                        onChange={(e) => {
                          setLifeProblem(parseInt(e.target.value));
                        }}
                      >
                        <option value="">-- Select One --</option>
                        <option value="0">Months</option>
                        <option value="1">Years</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group
                      className="form-group"
                      controlId="formGridState"
                    >
                      <Form.Label>Life Saving</Form.Label>
                      <ToolKit text={'Select if your solution can save someone’s life. E.g., a drowning detection camera or an advanced gas leakage monitoring system in a mine.'} to={screenWidth < 640 ? "center" : 'right'} />

                      <Form.Select
                        disabled={scoredata ? true : false}
                        value={lifesaving}
                        onChange={(e) => {
                          setLifeSaving(parseInt(e.target.value));
                        }}
                      >
                        <option value="">-- Select One --</option>
                        <option value="0">No</option>
                        <option value="10">Yes</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group
                      className="form-group"
                      controlId="formGridState"
                    >
                      <Form.Label>Convenience / Comfort</Form.Label>
                      <ToolKit text={'Select yes or no here. You are selecting if the solution is falling under providing comfort or convenience type of service. For e.g. an app that stores boarding pass for easy access. An app allowing you to manage your travel expense. Leve this field unselected if it does not apply.'} to={screenWidth < 400 ? "left" : screenWidth < 640 ? "center" : 'right'} />

                      <Form.Select
                        disabled={scoredata ? true : false}
                        value={conveniencecomfort}
                        onChange={(e) => {
                          setConvenienceComfort(parseInt(e.target.value));
                        }}
                      >
                        <option value="">-- Select One --</option>
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group
                      className="form-group"
                      controlId="formGridState"
                    >
                      <Form.Label>Regulatory Requirement</Form.Label>
                      <ToolKit text={'Select if your solution is solving any regulatory / compliance requirements for customers.'} to={screenWidth < 400 ? "left" : screenWidth < 640 ? "center" : 'right'} />

                      <Form.Select
                        disabled={scoredata ? true : false}
                        value={regulatory}
                        onChange={(e) => {
                          setRegulatory(parseInt(e.target.value));
                        }}
                      >
                        <option value="">-- Select One --</option>
                        <option value="0">No</option>
                        <option value="5">Yes</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <Form.Group
                      className="form-group"
                      controlId="formGridState"
                    >
                      <Form.Label>Security</Form.Label>
                      <ToolKit text={'If the solution is in security domain and if the solution enhance your security posture, prevent an event from happening or allow you to respond to security incident after it occurred.'}
                        to={'right'} />

                      <Form.Select
                        disabled={scoredata ? true : false}
                        value={security}
                        onChange={(e) => {
                          setSecurity(parseInt(e.target.value));
                        }}
                      >
                        <option value="">-- Select One --</option>
                        <option value="1">Enhance</option>
                        <option value="4">Prevent</option>
                        <option value="2">Respond</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group
                      className="form-group"
                      controlId="formGridState"
                    >
                      <Form.Label>Revenue Impact Opportunity</Form.Label>
                      <ToolKit
                        text={'Select if your solutions help companies to drive/increase revenue by 1x, 10x, or 100x'}
                        to={screenWidth < 400 ? "left" : screenWidth < 640 ? "center" : 'right'}
                      />

                      <Form.Select
                        disabled={scoredata ? true : false}
                        value={revenueopp}
                        onChange={(e) => {
                          setRevenueopp(parseInt(e.target.value));
                        }}
                      >
                        <option value="">-- Select One --</option>
                        <option value="2">1X</option>
                        <option value="4">10X</option>
                        <option value="8">100X</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <Form.Group
                      className="form-group"
                      controlId="formGridState"
                    >
                      <Form.Label>Motivation Parity Transition</Form.Label>
                      <ToolKit text={'Select if the motivation parity transition is in a positive direction, negative direction, or does not change with time. This is an advanced concept, so please watch the motivation parity video series in either the video library or at the bottom of <a href="https://paybacck.com/user/buyermotivation" >this page</a>'} to={screenWidth < 400 ? "left" : screenWidth < 640 ? "center" : 'right'} />

                      <Form.Select
                        disabled={scoredata ? true : false}
                        value={motivationTransition}
                        onChange={(e) => {
                          setMotivationTransition(parseInt(e.target.value));
                        }}
                      >
                        <option value="">-- Select One --</option>
                        <option value="1">Positive</option>
                        <option value="-2">Negative</option>
                        <option value="0">No Transition</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group
                      className="form-group"
                      controlId="formGridState"
                    >
                      <Form.Label>Compelling Event</Form.Label>
                      <ToolKit text={'Select if the problem has any compelling event attached to it, this is This is an advance concept so please watch the motivation parity video series in either video library or, at the bottom of <a href="https://paybacck.com/user/buyermotivation" >this page</a>'} to={screenWidth < 640 ? "center" : 'right'} />

                      <Form.Select
                        disabled={scoredata ? true : false}
                        value={event}
                        onChange={(e) => {
                          setEvent(parseInt(e.target.value));
                        }}
                      >
                        <option value="">-- Select One --</option>
                        <option value="2">Yes</option>
                        <option value="-2">No</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="col-md-6">
                    <Form.Group
                      className="form-group"
                      controlId="formGridState"
                    >
                      <Form.Label>Compelling Event Frequency</Form.Label>
                      <ToolKit text={'Select the frequency of the compelling event. This may be the same as the frequency of the problem, but, in some cases, it is different than the frequency of the problem. To know more about this, please watch the video in the video library or on <a href="https://paybacck.com/user/buyermotivation" >this page</a>'} to={screenWidth < 400 ? "left" : screenWidth < 640 ? "center" : 'right'} />

                      <Form.Select
                        disabled={scoredata ? true : false}
                        value={eventFrequency}
                        onChange={(e) => {
                          setEventFrequency(parseInt(e.target.value));
                        }}
                      >
                        <option value="">-- Select One --</option>
                        <option value="3">Daily</option>
                        <option value="3">Weekly</option>
                        <option value="2">Monthly</option>
                        <option value="1">Multiple times a year</option>
                        <option value="-1">Yearly</option>
                        <option value="-3">Other</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="problem-total">
                      <span>Total</span>
                      <Form.Control className="form-input-total" type="text" readOnly value={total} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="buyer-motivation ">
                <div className="row">
                  <div className="col-md-12">
                    {/* <div className="what-video">
                      <h3>Watch the video</h3>
                      <img src="../img/what-video.png" alt="" />
                    </div> */}
                    <div className="next-back-button">
                      {loader}
                      <Button
                        variant="primary button-green"
                        onClick={backButton}
                      >
                        Back
                      </Button>
                      <Button
                        variant="primary button-blue"
                        onClick={(e) => submitData(e)}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dimentional-title" style={{ padding: 0, marginTop: 18 }}>
                <h2 style={{ color: "black" }} id="videos_title">Training videos: how it works.</h2>
              </div>
              <VideoContainer ids={[8]} />
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}
