import React, { useEffect, useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { Done } from "@mui/icons-material";
import { downloadpdf } from "../service/auth";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import Terms from "../Frontend/Terms";
import ReactLoading from "react-loading";
var validator = require("email-validator");

const Lead = () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [job, setJob] = useState('')
    const [type] = useState("pdf_download")
    const [terms, setTerms] = useState(false)
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false);

    const [showAlertMsg, setAlertMsg] = useState({ type: "", msg: "" });
    const [showAlert, setAlert] = useState(false);

    const modalClose = () => setShow(false);
    const modalShow = () => setShow(true);
    function AlertShow(props) {
        if (showAlert) {
            return (
                <Alert variant={props.type} >
                    <Alert.Heading>Alert !</Alert.Heading>
                    <p>{props.text}</p>
                </Alert>
            );
        }
    }

    const download = async () => {

        setAlertMsg({ type: "", msg: "" })
        setAlert(false)
        setLoading(false)
        try {
            if (!name) {
                setAlertMsg({ type: "danger", msg: "Please enter your name !" })
                setAlert(true)
                return
            }
            if (!job) {
                setAlertMsg({ type: "danger", msg: "Please enter job title !" })
                setAlert(true)
                return

            }

            let mails = ['gmail', 'yahoo', 'hotmail', 'aol']
            let emailErr = false;
            mails.forEach((mail) => {
                if (email.includes(mail)) {
                    console.log(mail)
                    setAlertMsg({ type: "danger", msg: "Please enter valid email address !" });
                    setAlert(true);
                    emailErr = true;
                    return
                }
            })

            // if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
            if (!validator.validate(email)) {
                setAlertMsg({ type: "danger", msg: "Please enter valid email address !" })
                setAlert(true)
                return
            }

            if (!/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(phone)) {
                setAlertMsg({ type: "danger", msg: "Please enter valid phone no. !" })
                setAlert(true)
                return

            }
            if (!emailErr) {
                if (!terms) {
                    setAlertMsg({ type: "danger", msg: "Please accept terms and conditions!", });
                    setAlert(true)
                    return
                }
                let password = new Date().getTime();
                setLoading(true)
                let res = await downloadpdf(name, email, password, phone, job, type);
                //alert(JSON.stringify(res))
                if (res.data.success) {
                    setName('');
                    setEmail('');
                    setPhone('');
                    setJob('');
                    setTerms(false)
                    setAlertMsg({ type: "", msg: "" })
                    setAlert(false)
                    window.open('/Paybacck_Documentation_Pages.pdf')

                } else {
                    setAlertMsg({ type: "danger", msg: "some error occurred !", });
                    setAlert(true)
                }
                setLoading(false)

            }
        } catch (err) {
            if (err.response.status === 422) {
                setAlertMsg({ type: "danger", msg: "Fill all the details", });
            }
            if (err.response.status === 401) {
                setAlertMsg({ type: "danger", msg: err.response.data.error.email[0] });
            }
            setAlert(true);
        }

    }

    useEffect(() => {
        setAlert(false);
        setAlertMsg({ type: "", msg: '' })
    }, [name, email, job, phone])

    return (
        <div className="leadgenerateLead pb-5" >
            <Container>
                <Terms show={show} modalClose={() => modalClose()} />

                <div className="row">
                    <div style={{ width: "300px", margin: '50px 0px' }}>
                        <img src="img/logo.png" />
                    </div>
                </div>
                <div className="first-container">
                    <div className="first-container-left">
                        <div className="lead-first-heading" >
                            How about never making a bad
                            digital investment?
                        </div>
                        <div className="lead-first-para" style={{ fontSize: '20px' }}>
                        Paybacck is first financial gating and product formulation
                            solution, changing how companies invest in technology
                        </div>
                        <div className="lead-second-heading" >
                            Organizations adopting Paybacck
                            cut 32% IT costs on average
                        </div>
                        <div className="lead-second-para" >
                            Take a test drive, evaluate your current Initiatives, find
                            out which ones will fail vs. which ones will deliver results
                        </div>
                        <div>
                            <Link to={'/signup'} ><Button className="btn-dark py-3 px-5 fs-5">Sign up for trial </Button></Link>
                        </div>
                        <div className="lead-third-para"  >
                            Game-changing digital strategy that works for you. Digital
                            Transformation - What next? Where to invest? How to avoid failures
                            & ensure returns. Organizations using Paybacck are six times more
                            likely to find profitable innovations
                        </div>
                        <div className="lead-forth-para">
                            Visionary leaders are using Paybacck to cut costs, generate
                            massive growth, avoid failures, and race ahead.</div>
                    </div>
                    <div className="first-container-right">
                        <div style={{ backgroundColor: 'black' }}>
                            <div style={{}}>
                                <div style={{ fontSize: '20px', textAlign: 'center', padding: '20px' }}>How Paybacck easily predicts failure of 20 startups</div>
                                <div style={{ textAlign: 'center', padding: '0 10px 10px 10px', fontSize: '16px' }}>How Paybacck’s 16 tools provide confident answers to your complex problems</div>
                            </div>
                            <div className="w-100 mb-4 lead-form-container" style={{ backgroundColor: 'black', padding: '0 100px' }}>
                                <Form style={{ paddingBottom: "30px" }}>
                                    <AlertShow type={showAlertMsg.type} text={showAlertMsg.msg} />

                                    <Form.Group className="lead-form-group">
                                        <Form.Label className="lead-form-lable" > <span className="text-danger">* </span>Name</Form.Label>
                                        <Form.Control
                                            className="lead-form"
                                            name="Name"
                                            type="text"
                                            placeholder="Full Name"
                                            autoComplete="off"
                                            required
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                        <Form.Control.Feedback></Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="d-flex lead-form-group">
                                        <Form.Label className="lead-form-lable"><span className="text-danger">* </span>Job Title</Form.Label>
                                        <Form.Control
                                            className="lead-form"

                                            name="Job Title"
                                            type="text"
                                            placeholder="Job title"
                                            autoComplete="off"
                                            required
                                            value={job}
                                            onChange={(e) => setJob(e.target.value)}
                                        />
                                        <Form.Control.Feedback></Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="d-flex lead-form-group">
                                        <Form.Label className="lead-form-lable"><span className="text-danger">* </span>Work Email</Form.Label>
                                        <Form.Control
                                            className="lead-form"

                                            name="Work email"
                                            type="text"
                                            placeholder="Email"
                                            autoComplete="off"
                                            required
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <Form.Control.Feedback></Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="d-flex lead-form-group">
                                        <Form.Label className="lead-form-lable"><span className="text-danger">* </span>Phone</Form.Label>

                                        <Form.Control
                                            className="lead-form"
                                            name="Phone"
                                            type="text"
                                            placeholder="Phone no."
                                            autoComplete="off"
                                            required
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                        />
                                        <Form.Control.Feedback></Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="d-flex lead-form-group">

                                        {/* <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}> */}
                                        <Form.Label className="lead-form-lable" >
                                            <Form.Check
                                                inline
                                                name="group"
                                                type="checkbox"
                                                checked={terms}
                                                onChange={(e) => setTerms(!terms)}
                                            />

                                        </Form.Label>
                                        <span style={{ color: "", textDecoration: "underline", cursor: "pointer", width: '100%' }} onClick={() => { modalShow() }}>By signing up I accept terms, conditions & privacy policy.</span>
                                        {/* </div> */}
                                    </Form.Group>
                                    <Form.Group className="d-flex lead-form-group">
                                        <Form.Label className="lead-form-lable"></Form.Label>
                                        <Button onClick={download} className="lead-form w-100" style={{ backgroundColor: "#ffd866", color: 'black', }}>{loading ? <div style={{ width: 'fit-content', margin: 'auto' }} ><ReactLoading type="bubbles" color="#162c5d" width={50} /></div> : "DOWNLOAD"}</Button>
                                        <Form.Control.Feedback></Form.Control.Feedback>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                        <div className="right-panel" >
                            <div className="imagery-with-content">
                                <div className="hero-image">
                                    <iframe
                                        class="embed-responsive-item"
                                        src={"https://www.youtube.com/embed/pfapAbQOaHg?controls=0&autoplay=1&loop=1&&playlist=pfapAbQOaHg&rel=0"}
                                        width="100%"
                                        height="300"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>

    )
}

export default Lead