import React, { useEffect, useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { Done } from "@mui/icons-material";
import { downloadpdf } from "../service/auth";
import { Alert } from "react-bootstrap";
const LeadGeneratpage = () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [job, setJob] = useState('')
    const [type] = useState("pdf_download")

    const [showAlertMsg, setAlertMsg] = useState({ type: "", msg: "" });
    const [showAlert, setAlert] = useState(false);

    function AlertShow(props) {
        if (showAlert) {
            return (
                <Alert variant={props.type}>
                    <Alert.Heading>Alert !</Alert.Heading>
                    <p>{props.text}</p>
                </Alert>
            );
        }
    }

    const download = async () => {
        try {
            if (!name) {
                setAlertMsg({ type: "danger", msg: "Please enter your name !" })
                setAlert(true)
                return
            }
            if (!job) {
                setAlertMsg({ type: "danger", msg: "Please enter job title !" })
                setAlert(true)
                return

            }

            let mails = ['gmail' , 'yahoo' , 'hotmail' , 'aol']
            let emailErr = false;
            mails.forEach((mail)=>{
                if(email.includes(mail)){
                    console.log(mail)
                    setAlertMsg({ type: "danger", msg: "please enter valid email address !" });
                    setAlert(true);
                    emailErr=true;
                    return
                }
            })

            if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
                setAlertMsg({ type: "danger", msg: "please enter valid email address !" })
                setAlert(true)
                return

            }
            if (!/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(phone)) {
                setAlertMsg({ type: "danger", msg: "Please enter valid phone no. !" })
                setAlert(true)
                return

            }
            if(!emailErr){

                let password = new Date().getTime();
                let res = await downloadpdf(name, email, password, phone, job, type);
                //alert(JSON.stringify(res))
                if(res.data.success){
                    setName('');
                    setEmail('');
                    setPhone('');
                    setJob('');
                    window.open('/Paybacck_Documentation_Pages.pdf')

                }else{
                    setAlertMsg({ type: "danger", msg: "some error occurred !", });
                    setAlert(true)
                }
                
            }
        } catch (err) {
            if (err.response.status === 422) {
                setAlertMsg({ type: "danger", msg: "Fill all the details", });
            }
            if (err.response.status === 401) {
                setAlertMsg({ type: "danger", msg: err.response.data.error.email[0] });
            }
            setAlert(true);
        }

    }

    useEffect(() => {
        setAlert(false);
        setAlertMsg({ type: "", msg: '' })
    }, [name, email, job, phone])

    return (
        <div className="leadgenerate">
            <Container>
                {/* <div className="row">
                    <div className="heading-inner-pages ">
                        <h1 className="about-heading">
                            Lead <span>generate</span>
                        </h1>
                    </div>
                </div> */}

                <div className="row">
                    <div style={{paddingTop:'60px'}}>
                        <div style={{ fontSize: "20px", marginTop: '50px' }}>
                        Paybacck is financial gating and product formulation solution.
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div style={{ margin: "15px 0px 40px", fontSize: "24px" , color: "#ffd866"}}>
                        Visionary leaders use Paybacck to cut costs, generate massive growth, and avoid failures.
                    </div>
                </div>
                <div className="first-container ">
                    <div className="first-container-left">
                        <p>Paybacck catches bad digital investments before you commit.</p>
                        <p>Paybacck helps you pick the most unpromising initiatives for
                            cost-cutting.</p>
                        <p>Paybacck allows you to continue innovation and
                            transformation while cutting costs.</p>
                        <p>Paybacck helps you pivot the existing products for growth
                            and profitability.</p>
                        <p>Paybacck allows you to deliver the right solutions
                            cheaper, faster, and better.</p>
                        <p>Paybacck helps you prioritize diverse digital initiatives
                            accurately.</p>
                        <p>Paybacck brings improves employee retention and work-life balance.</p>
                        <p>Paybacck helps you formulate products to gain early traction
                            & sustainable growth, and profits.</p>
                    </div>
                    <div className="first-container-right">
                    <div style={{backgroundColor:'black'}}>
                            <div style={{}}>
                                <div style={{fontSize:'20px', textAlign:'center' , padding:'20px'}}>How Paybacck easily predicts failure of 20 startups</div>
                                <div style={{textAlign:'center' , padding:'0 10px 10px 10px' , fontSize:'16px'}}>How Paybacck's 16 tools provide confident answers to your complex problems</div>
                            </div>
                        <div className="w-100 mb-4 lead-form-container" style={{ backgroundColor: 'black', padding: '0 100px' }}>
                           <Form style={{ paddingBottom: "30px" }}>
                                <AlertShow type={showAlertMsg.type} text={showAlertMsg.msg} />

                                <Form.Group className="lead-form-group">
                                    <Form.Label className="lead-form-lable" > <span className="text-danger">* </span>Name</Form.Label>
                                    <Form.Control
                                        className="lead-form"
                                        name="Name"
                                        type="text"
                                        placeholder="Name"
                                        autoComplete="off"
                                        required
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <Form.Control.Feedback></Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="d-flex lead-form-group">
                                    <Form.Label className="lead-form-lable"><span className="text-danger">* </span>Job Title</Form.Label>
                                    <Form.Control
                                        className="lead-form"

                                        name="Job Title"
                                        type="text"
                                        placeholder="Job title"
                                        autoComplete="off"
                                        required
                                        value={job}
                                        onChange={(e) => setJob(e.target.value)}
                                    />
                                    <Form.Control.Feedback></Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="d-flex lead-form-group">
                                    <Form.Label className="lead-form-lable"><span className="text-danger">* </span>Work Email</Form.Label>
                                    <Form.Control
                                        className="lead-form"

                                        name="Work email"
                                        type="text"
                                        placeholder="Email"
                                        autoComplete="off"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <Form.Control.Feedback></Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="d-flex lead-form-group">
                                    <Form.Label className="lead-form-lable"><span className="text-danger">* </span>Phone</Form.Label>
                                    <Form.Control
                                        className="lead-form"
                                        name="Phone"
                                        type="text"
                                        placeholder="Phone no."
                                        autoComplete="off"
                                        required
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                    <Form.Control.Feedback></Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="d-flex lead-form-group">
                                    <Form.Label className="lead-form-lable"></Form.Label>
                                    <Button onClick={download} className="lead-form w-100" style={{ backgroundColor: "#ffd866", color: 'black' ,  }}>DOWNLOAD</Button>
                                    <Form.Control.Feedback></Form.Control.Feedback>
                                </Form.Group>
                            </Form>
                        </div>
                        </div>

                        <div className="right-panel">
                            <div className="imagery-with-content">
                                <div className="hero-image">
                                    {/* <img src="../img/banner.png" alt="GATEZERO" /> */}
                                    <iframe
                                        class="embed-responsive-item"
                                        src={"https://www.youtube.com/embed/pfapAbQOaHg?controls=0&autoplay=1&loop=1&&playlist=pfapAbQOaHg&rel=0"}
                                        width="100%"
                                        height="300"
                                    ></iframe>
                                </div>
                                {/* <div className="desi-1">
                                    <ul>
                                        <li>Pick the right idea</li>
                                        <li>Build the right product</li>
                                        <li>Go laughing to the bank - Pure Joy!!</li>
                                        <li className="bullet">3 decades of digital expereince poured into tangible scorecards for avoiding losses and designing success.</li>
                                    </ul>
                                    </div> */}
                            </div>

                        </div>
                    </div>
                </div>
                <div className="lead-generate">
                    <div style={{ margin: "40px 0px", fontSize: "24px" , color: "#ffd866" }}>

                    Paybacck is ground breaking solution for digital investment gating and product formulation
                    </div>
                    <div className="second-container" >
                        <div className="left-component" style={{ marginRight: '50px' }} >
                            <p>As a leader, you know businesses need to be digital today, but the corporate US loses
                                nearly $280 billion in failed digital solutions each year. That's where Paybacck comes in -
                                we help you manage the "why" and "what" of digital investments, providing a solid
                                framework to deploy your capital for maximum impact, stop bad IT investments, and
                                ensure sustainable returns from your digital transformation and innovation. Paybacck's 16
                                tools enable confident decision-making to meet your business goals. Visionary leaders
                                today use Paybacck to lead their organizations to the next level of success and
                                transformation.</p>
                            <p className="mt-5">Paybacck is an unrivaled tool for growing profits and revenue, launching innovative
                                products and services, navigating crises, and building sustainable business models.
                                Paybacck provides value from the get-go. Register today for a free trial and training
                                program. </p>
                        </div>
                        <div className="right-component">
                            <div className=" check" style={{ width: '350px', border: '2px solid white', padding: "10px 0px" }}>
                                <p>

                                    <span><Done /></span><span>Navigate crisis</span>
                                </p>
                                <p>

                                    <span><Done /></span><span>Accurate cost cutting</span>
                                </p>
                                <p>

                                    <span><Done /></span><span>Avoid digital failures</span>
                                </p>
                                <p>

                                    <span><Done /></span><span>Innovation</span>
                                </p><p>

                                    <span><Done /></span><span>Digital transformation</span>
                                </p><p>

                                    <span><Done /></span><span>Profitability and growth</span>
                                </p><p>

                                    <span><Done /></span><span>Meet business goals</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="third-section">

                    <div style={{ margin: "40px 0px", fontSize: "24px" ,color: "#ffd866" , textAlign:'center' , padding:"0 20px" }}>
                    Paybacck for visionary leaders wanting to turnaround their organizations and leave a legacy
                    </div>
                </div>
                <div>
                    <div className="third-container" style={{ margin:"0 auto" }}>

                        <div
                            className="lead-form"
                            style={{ width: "100%", textAlign: 'center', lineHeight: 2.5, fontSize: 18, fontWeight: 'bold', backgroundColor: '#ffd866', color: 'black', marginBottom: "20px" }}
                        >
                            Enroll for free trial and free training
                        </div>

                        <div style={{backgroundColor:'black'}}>
                            <div style={{}}>
                                <div style={{fontSize:'20px', textAlign:'center' , padding:'20px'}}>How Paybacck easily predicts failure of 20 startups</div>
                                <div style={{textAlign:'center' , padding:'0 10px 10px 10px' , fontSize:'16px'}}>How Paybacck’s 16 tools provide confident answers to your complex problems</div>
                                
                            </div>
                        <div className=" mb-4 lead-form-container" style={{ padding: '0 100px' }}>
                           <Form style={{ paddingBottom: '30px' }}>
                                <AlertShow type={showAlertMsg.type} text={showAlertMsg.msg} />

                                <Form.Group className="lead-form-group">
                                    <Form.Label className="lead-form-lable" ><span className="text-danger">* </span>Name</Form.Label>
                                    <Form.Control
                                        className="lead-form"
                                        name="Name"
                                        type="text"
                                        placeholder="Name"
                                        autoComplete="off"
                                        required
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <Form.Control.Feedback></Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="d-flex lead-form-group">
                                    <Form.Label className="lead-form-lable"><span className="text-danger">* </span>Job Title</Form.Label>
                                    <Form.Control
                                        className="lead-form"

                                        name="Job Title"
                                        type="text"
                                        placeholder="Job title"
                                        autoComplete="off"
                                        required
                                        value={job}
                                        onChange={(e) => setJob(e.target.value)}
                                    />
                                    <Form.Control.Feedback></Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="d-flex lead-form-group">
                                    <Form.Label className="lead-form-lable"><span className="text-danger">* </span>Work Email</Form.Label>
                                    <Form.Control
                                        className="lead-form"

                                        name="Work email"
                                        type="text"
                                        placeholder="Email"
                                        autoComplete="off"
                                        required
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                    <Form.Control.Feedback></Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="d-flex lead-form-group">
                                    <Form.Label className="lead-form-lable"><span className="text-danger">* </span>Phone</Form.Label>
                                    <Form.Control
                                        className="lead-form"
                                        name="Phone"
                                        type="text"
                                        placeholder="Phone no."
                                        autoComplete="off"
                                        required
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                    />
                                    <Form.Control.Feedback></Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="d-flex lead-form-group">
                                    <Form.Label className="lead-form-lable"></Form.Label>
                                    {/* <Form.Control
                                        className="lead-form"
                                        name="Phone"
                                        type="text"
                                        placeholder="Phone no."
                                        autoComplete="off"
                                        required
                                    //value={fullname}
                                    //onChange={(e) => setFullName(e.target.value)}
                                    /> */}
                                    <Button onClick={download} className="lead-form w-100" style={{ backgroundColor: "#ffd866", color: 'black' }}>DOWNLOAD</Button>
                                    <Form.Control.Feedback></Form.Control.Feedback>
                                </Form.Group>
                            </Form>
                        </div>
                        </div>
                    </div>
                </div>
                <div style={{ margin: "40px 0px", fontSize: "24px", color: "#ffd866",  textAlign:'center' , padding:"0 20px" }}>
                Paybacck is for all corporates leveraging digital technology, venture capital firms, founders,
                    executive leaders, entrepreneurs, product owners, and anyone involved with making investment
                    decisions
                </div>
            </Container>
        </div>

    )
}

export default LeadGeneratpage