import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import Header from "./Header";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import ReactLoading from "react-loading";

import { Link } from "react-router-dom";
import { current } from "@reduxjs/toolkit";

const baseUrl = process.env.REACT_APP_BASE_URL_API;

function InovationBoard() {
  const quadrant1 = [
    { id: 1, value: "1" , text:"No clear alternative exists" },
    { id: 2, value: "0" , text: "Alternatives with pain points"},
    { id: 3, value: "-1", text: "Mature Alternatives Exists " },
  ];

  const quadrant2 = [
    { id: 1, value: "6" , text:"No clear alternative exists" },
    { id: 2, value: "3" , text:"Alternatives with pain points" },
    { id: 3, value: "2" , text: "Mature Alternatives Exists" },
  ];

  const quadrant3 = [
    { id: 1, value: "-1" , text: "No clear alternative exists" },
    { id: 2, value: "-4" , text: "Alternatives with pain points"},
    { id: 3, value: "-10", text: "Mature Alternatives Exists"  },
  ];

  const quadrant4 = [
    { id: 1, value: "2" , text:"No clear alternative exists" },
    { id: 2, value: "1" , text: "Alternatives with pain points"},
    { id: 3, value: "0" , text: "Mature Alternatives Exists"},
  ];

  const headerdata = () => {
    let token = localStorage.getItem("token");
    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
  };

  let { id } = useParams();

  const navigate = useNavigate();

  const [values, setValues] = useState({ cas: 0, event_frequency_score : 0 });
  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);

  const [customer, setCustomer] = useState(false);
  const [actor, setActor] = useState(false);
  const [stakeholders, setStakeholders] = useState(false);
  const [showinput, setshowinput] = useState(false);
  let [errors, setErrors] = useState({});

  const [quadrant, setQuadrant] = useState("");
  const [quadrantValues, setQuadrantValues] = useState([]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      setLoadError(false);

      axios
        .get(
           "getinnovation/" + id,
          headerdata()
        )
        .then((resp) => {
          setLoading(false);
          if (resp.data.success) {
            let res = resp.data.data;
            delete res["id"];
            delete res["created_at"];
            delete res["updated_at"];
            delete res["user_id"];
            delete res["status"];
            if (res?.customer) setCustomer(true);
            if (res?.actor) setActor(true);
            if (res?.stakeholders) setStakeholders(true);
            res.event_frequency_score = res?.event_frequency_score || 0;
            setValues(res);
            setQuadrant(res?.position);
            setshowinput(res?.compelling_event === "2");
          } else {
            setLoadError(true);
          }
        })
        .catch((err) => {
          setLoading(false);
          setLoadError(true);
          console.log(err);
        });
    } else setLoading(false);
  }, [id]);

  const setValue = function (type, value) {
  //  

    var vals = {}, errs = {};
    Object.entries(values).map( ([k,v])=>{
      vals[k] = v;
    });
    Object.entries(errors).map( ([k,v])=>{
      errs[k] = v;
    });
    if(type == "position"){
      if(vals.subcategory){
         vals.subcategory = ''
      }
    }
    vals[type] = value;
    if (vals[type] === "") delete vals[type];
    else {
      if( errs[type])
      delete errs[type];
    }

    setValues(vals);
    setErrors(errs);
  };

  const setting_Form = () => {
    if (quadrant === "1") {
      setQuadrantValues(quadrant1);
    } else if (quadrant === "2") {
      setQuadrantValues(quadrant2);
    } else if (quadrant === "3") {
      setQuadrantValues(quadrant3);
    } else if (quadrant === "4") {
      setQuadrantValues(quadrant4);
    } else if (quadrant === "") {
      setQuadrantValues([]);
    }
  };

  const total_val = () => {
    let totalVal = 0;

    if (customer === true) totalVal = 1;

    if (actor === true) totalVal = -3;

    if (stakeholders === true) totalVal = -1;

    if (customer === true && actor === true) totalVal = 2;

    if (actor === true && stakeholders === true) totalVal = -1;

    if (customer === true && stakeholders === true) totalVal = 2;

    if (customer === true && actor === true && stakeholders === true)
      totalVal = 5;
        
    setValue("cas", totalVal);
  };

  const text_input = () => {
    return (
      <>
        <div className="d-flex justify-content-between align-items-center mx-4 ">
          <Form.Label> &nbsp;</Form.Label>
          <Form.Control
            style={{ width: "175px" }}
            defaultValue={values?.compelling_event_text || ""}
            onChange={(e) => setValue("compelling_event_text", e.target.value)}
          ></Form.Control>
        </div>
      </>

    );
  };

  const setEventScore = (val) => {
    var score = 0;
    if( val === "1" ) score = 3;
    if( val === "2" ) score = 3;
    if( val === "3" ) score = 2;
    if( val === "4" ) score = 1;
    if( val === "5" ) score = -1;
    if( val === "6" ) score = -3;
    return score;    
  };

  

  const getScore = (data) => {
   var totalscore =  parseInt(data["frequency"]) + parseInt(data["compelling_event"]) + parseInt(data["subcategory"]) + parseInt(data["event_frequency_score"]) + parseInt(data["transition"]) + parseInt(data["cas"]) + parseInt(data["market_size"])
    return totalscore;
  };
  const validateForm = (fData) => {
    errors = {};

    let reqFields = [
      "title",
      "position",
      "market_problem",
      "frequency",
      "subcategory",
      "transition",
      "market_size",
      "compelling_event",
      "event_frequency",

    ];
    reqFields.map((fld) => {
      if (!fData[fld]) errors[fld] = "This field is required";
      if (fData[fld] && fData[fld] == "")
        errors[fld] = "This field is required";
    });
    setErrors(errors);
    //
  };

  const handle_Submit = (e) => {
    e.preventDefault();
    var fData = values;
    fData = {
      ...fData,
      ...{
        customer: customer ? 1 : 0,
        actor: actor ? 1 : 0,
        stakeholders: stakeholders ? 1 : 0,
      },
    };
    validateForm(fData);

    console.log(fData);

    if (Object.keys(errors).length == 0) {
      fData["event_frequency_score"] =setEventScore(fData?.event_frequency || 0);
      fData["score"] = getScore(fData);
      
      const headers = headerdata();
      axios
        .post(
          baseUrl+ "setinnovation" +
            (id ? "/" + id : ""),
          fData,
          headers
        )
        .then((res) => {
          console.log(res.data.success);
          navigate("/user");
        })
        .catch((err) => console.log(err));
    } else {
      window.scrollTo(0, 0);
    }
  };
  

  useEffect(() => {
    setting_Form();
  }, [quadrant]);

  useEffect(()=>{

    total_val()

  },[customer , actor , stakeholders])

  return (
    <>
      <div className="dashbord-page">
        <div style={{ position: "absolute", top: 0, left: 0 }}>
          <Header />
        </div>
        <div className="container">
          <div className="dimentional-title inovationBoard-heading">
            <h2>Innovation Board</h2>
            {id && <p>ID: {id}</p>}
          </div>

          <div className="inovative-page inovative-page-v2">
            {loading && (
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 30,
                }}
              >
                <ReactLoading type="bubbles" color="#162c5d" width={100} />
              </div>
            )}
            {loadError && (
              <div
                style={{
                  textAlign: "center",
                  fontSize: 16,
                  color: "#d00",
                  fontWeight: "bold",
                }}
              >
                Ops, seems something wrong !
              </div>
            )}
            {!loading && !loadError && (
              <div className="inovative-form">
                <Form
                  noValidate
                  onSubmit={(e) => handle_Submit(e)}
                  className="form"
                >
                  <div className="">
                    <Form.Group className="mb-4 d-flex align-items-center px-4 my-2 ">
                      <Form.Label><span className="text-danger">* </span>Title</Form.Label>
                      <Form.Control
                      maxLength={100}
                        style={{ width: "100%" }}
                        required
                        type="text"
                        placeholder="Title"
                        defaultValue={values?.title || ""}
                        onChange={(e) => setValue("title", e.target.value)}
                      />
                    </Form.Group>
                    {errors?.title && (
                      <div className="fielderror">{errors?.title}</div>
                    )}
                    <Form.Group className="mb-4 d-flex align-items-center px-4 my-2 ">
                      <Form.Label><span className="text-danger">* </span>Market Needs / Problems</Form.Label>
                      <Form.Control
                      maxLength={1200}
                        style={{ width: "100%" }}
                        required
                        as="textarea"
                        rows={5}
                        type="text"
                        placeholder="Market Needs / Problem"
                        defaultValue={values?.market_problem || ""}
                        onChange={(e) =>
                          setValue("market_problem", e.target.value)
                        }
                      />
                    </Form.Group>
                    {errors?.market_problem && (
                      <div className="fielderror">{errors?.market_problem}</div>
                    )}
                  </div>
                  <Row>
                    <Col md={6} className="col">
                      <Form.Group
                        className="mb-4 d-flex align-items-center px-4 my-4 "
                        style={{ width: "fit-content" }}
                      >
                        <Form.Label><span className="text-danger">* </span>
                          Buyer Motivation Quadrant Position
                        </Form.Label>
                        <Form.Select
                          value={values?.position}
                          onChange={(e) => {
                            setQuadrant(e.target.value);
                            setValue("position", e.target.value);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="1">1st Quadrant</option>
                          <option value="2">2nd Quadrant</option>
                          <option value="3">3rd Quadrant</option>
                          <option value="4">4th Quadrant</option>
                        </Form.Select>

                        <Form.Control.Feedback></Form.Control.Feedback>
                      </Form.Group>
                      {errors?.position && (
                        <div className="fielderror">{errors?.position}</div>
                      )}
                    </Col>
                    <Col  md={6}  className="right">
                      <Form.Group
                        className="mb-4 d-flex justify-content-between align-items-center px-4 my-4"
                        // style={{ width: "50%" }}
                      >
                        <Form.Label> <span className="text-danger">* </span>Frequency Of Problem</Form.Label>
                        <Form.Select
                          value={values?.frequency}
                          onChange={(e) => {
                            setValue("frequency", e.target.value);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="1">Low</option>
                          <option value="2">Medium</option>
                          <option value="3">High</option>
                        </Form.Select>
                        <Form.Control.Feedback></Form.Control.Feedback>
                      </Form.Group>
                      {errors?.frequency && (
                        <div className="fielderror">{errors?.frequency}</div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="col">
                      <Form.Group
                        className="mb-4 d-flex justify-content-between  align-items-center px-4 my-4"
                        controlId="formBasicName"
                        style={{ width: "fit-content" }}
                      >
                        <Form.Label><span className="text-danger">* </span>
                          Buyer Motivation Quadrant Subcategory
                        </Form.Label>
                        <Form.Select
                          required
                          value={values?.subcategory}
                          onChange={(e) => {
                            setValue("subcategory", e.target.value);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          {quadrantValues.map((item) => (
                            <option key={item.id} value={item.value}>
                              {item.text}
                            </option>
                          ))}
                          ;
                        </Form.Select>
                      </Form.Group>
                      {errors?.subcategory && (
                        <div className="fielderror">{errors?.subcategory}</div>
                      )}
                    </Col>
                    <Col md={6} className="right">
                      <Form.Group className="mb-4 d-flex justify-content-between  align-items-center px-4 my-4">
                          <Form.Label>Customer Actor Stakeholder</Form.Label>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                              }}
                            >
                              <Form.Check
                                name="customer"
                                checked={customer}
                                onChange={(e) => setCustomer(!customer)}
                              />
                              <div
                                style={{ width: "157px", margin: "0px 20px" , color: "black" }}
                              >
                                Customer
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                              }}
                            >
                              <Form.Check
                                name="actor"
                                checked={actor}
                                onChange={(e) => setActor(!actor)}
                              />
                              <div
                                style={{ width: "157px", margin: "0px 20px", color: "black" }}
                              >
                                Actor
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                              }}
                            >
                              <Form.Check
                                name="stakeholder"
                                checked={stakeholders}
                                onChange={(e) => setStakeholders(!stakeholders)}
                              />
                              <div
                                style={{ width: "157px", margin: "0px 20px" , color: "black" }}
                              >
                                Stakeholder
                              </div>
                            </div>
                          </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="col">
                      <Form.Group
                        className="mb-4 d-flex align-items-center px-4 my-4"
                        style={{ width: "fit-content" }}
                      >
                        <Form.Label><span className="text-danger">* </span>
                          Buyer Motivation Parity Transition with Time
                        </Form.Label>
                        <Form.Select
                          required
                          value={values?.transition}
                          onChange={(e) => {
                            setValue("transition", e.target.value);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="1">Positive</option>
                          <option value="0">No Transition</option>
                          <option value="-2">Negative</option>
                        </Form.Select>
                        <Form.Control.Feedback></Form.Control.Feedback>
                      </Form.Group>
                      {errors?.transition && (
                      <div className="fielderror">{errors?.transition}</div>
                    )}
                    </Col>
                    <Col md={6} className="right">
                      <Form.Group
                        className="mb-4 d-flex justify-content-between align-items-center px-4 my-4"
                        // style={{ width: "50%" }}
                      >
                        <Form.Label><span className="text-danger">* </span>Compelling Event</Form.Label>
                        {/* <div className="d-flex justify-content-end">
                      <div className=" w-100  "> */}

                        <Form.Select
                          required
                          value={values?.compelling_event}
                          onChange={(e) => {
                            setValue("compelling_event", e.target.value);
                            setshowinput(e.target.value === "2");
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="2">Yes</option>
                          <option value="-2">No</option>
                        </Form.Select>
                      </Form.Group>
                      {errors?.compelling_event && (
                      <div className="fielderror">{errors?.compelling_event}</div>
                      )}
                      {showinput ? text_input() : <></>}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="col">
                      <Form.Group
                        className="mb-4 d-flex align-items-center px-4 my-4"
                      >
                        <Form.Label><span className="text-danger">* </span>Market Size</Form.Label>
                        <Form.Select
                          required
                          value={values?.market_size}
                          onChange={(e) => {
                            setValue("market_size", e.target.value);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="-4">A few customers</option>
                          <option value="0">000’s of customers</option>
                          <option value="5">
                            Millions of customers
                          </option>
                        </Form.Select>
                      </Form.Group>
                      {errors?.market_size && (
                      <div className="fielderror">{errors?.market_size}</div>
                    )}
                    </Col>
                    <Col md={6} className="right">
                      <Form.Group
                        className="mb-4 d-flex justify-content-between align-items-center px-4 my-4"
                        // style={{ width: "50%" }}
                      >
                        <Form.Label><span className="text-danger">* </span>Compelling Event Frequency</Form.Label>
                        <Form.Select
                          required
                          value={values?.event_frequency}
                          onChange={(e) => {
                            setValue("event_frequency", e.target.value);
                          }}
                        >
                          <option value="">-- Select One --</option>
                          <option value="1">Daily</option>
                          <option value="2">Weekly</option>
                          <option value="3">Monthly</option>
                          <option value="4">Multiple times a year</option>
                          <option value="5">Yearly</option>
                          <option value="6">Other</option>
                        </Form.Select>

                        <Form.Control.Feedback></Form.Control.Feedback>
                      </Form.Group>
                      {errors?.event_frequency && (
                      <div className="fielderror">{errors?.event_frequency}</div>
                    )}
                    </Col>
                  </Row>
                  <div className=" d-flex justify-content-end px-4 next-back-button">
                    <Button
                      type="submit"
                      className="save-inov-btn"
                      variant=""
                      style={{color:"white"}}
                      // onClick={(e) => handle_Submit(e)}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default InovationBoard;
