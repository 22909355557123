import React, { Component } from "react";

//jQuery libraries

import "jquery/dist/jquery.min.js";

//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { AdminGetInitiative, deleteRow } from "../service/auth";
import { Button, Modal, Alert } from "react-bootstrap";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL_API;

class AdminListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [], show: false, num_of_days: 0, msg: "", show_alert: false, user_id: "", varient: "" };
    this.handleData = this.handleData.bind(this);
  }


  handleData() {
    $(document).ready(function () {
      $("#datatable_admin").DataTable({
        order: [[1, "desc"]],
        bDestroy: true,
        "language": {
          "emptyTable": "No scorecards created yet"
        }
      })
    });
  }


  componentDidMount() {
    try {
      AdminGetInitiative().then((res) => {
        if (res.data.data) {
          console.log(res.data.data)
          this.setState({ data: res.data.data });
          setTimeout(() => {
            this.handleData()
          }, 100)

        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  componentDidUpdate(prevProps) {
    // this.forceUpdate();
    if (this.props.date !== prevProps.date) {
      if (!this.props.date.fromdate || !this.props.date.todate) {
        AdminGetInitiative().then((res) => {
          $("#datatable_admin").DataTable().destroy()

          if (res.data.data) {
            // this.setState({ data: []});
            this.setState({ data: res.data.data });
            setTimeout(() => {
              this.handleData()
            }, 100)
          }
        });
      } else {

        let from = this.props.date.fromdate.getTime();
        let to = this.props.date.todate.getTime();


        AdminGetInitiative().then((res) => {
          $(document).ready(function () {
            $("#datatable_admin").DataTable().destroy()
          });
          if (res.data.data) {
            let data = res.data.data.filter((item) => {
              return new Date(item.created_at).getTime() >= from && new Date(item.created_at).getTime() <= to
            })
            // this.setState({ data: []});
            this.setState({ data: data })
            setTimeout(() => {
              this.handleData()
            }, 100)

          }
        });
      }
    }
  }

  delRowData = async (id) => {
    let data = await deleteRow(id);
    if (data.data) {
      let res = await AdminGetInitiative();
      this.setState({ data: res.data.data });
      this.forceUpdate();
    }
  };

  delRow = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Are you sure?</h1>
            <p>You want to delete this record?</p>
            <button class="btn btn-danger" onClick={onClose}>No</button>
            &nbsp;
            <button class="btn btn-primary"
              onClick={() => {
                this.delRowData(id);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      }
    });
  };

  modalClose = () => this.setState({ show: false })
  modalShow = () => {
    this.setState({ show_alert: false, msg: "", variant: "", show: true })
  }
  extend_trial = () => {
    let token = localStorage.getItem("token")
    var headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
    axios.get(baseUrl+`admin/extendfreetrial/${this.state.user_id}?days=${this.state.num_of_days}`, headers)
      .then((res) => {
        if (res.data.success) {
          this.setState({ msg: res.data.success, show_alert: true, varient: "success" })
        } else {
          this.setState({ msg: res.data.error, show_alert: true, varient: "danger" })
        }
      })
      .catch((err) => {
        this.setState({ msg: "Some error occured try again !", show_alert: true, varient: "danger" })

      })
      .finally(() => {
        this.modalClose()
      })
  }

  update_subscription = async (id, status) => {

    let token = localStorage.getItem("token")
    var headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
    var data = {
      status: status
    }
    await axios.post(baseUrl + "admin/updatesubscription/" + id, data, headers)

    if (!this.props.date.fromdate || !this.props.date.todate) {

      AdminGetInitiative().then((res) => {
        if (res.data.data) {
          // this.handleData();
          this.setState({ data: res.data.data });
        }
      });
    } else {

      let from = this.props.date.fromdate.getTime();
      let to = this.props.date.todate.getTime();


      AdminGetInitiative().then((res) => {
        if (res.data.data) {
          let data = res.data.data.filter((item) => {
            return new Date(item.created_at).getTime() >= from && new Date(item.created_at).getTime() <= to
          })
          this.setState({ data: data })
          // this.handleData();
        }
      });
    }
  }

  render() {
    return (
      <>
        <div className="MainDiv">
          <Modal show={this.state.show} onHide={this.modalClose}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "black", marginLeft: "20px" }}>Extend Trial</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 10,
                }}
              >
                <div>
                  <lable style={{ marginRight: "10px", color: "black" }}>Number of days</lable>
                  <input
                    type="number"
                    min="0"
                    max="1000"
                    onChange={(e) => { this.setState({ num_of_days: e.target.value }) }}
                    style={{ marginTop: "10px", fontSize: 16, padding: "5px 10px", borderRadius: "5px", borderColor: "gray", width: "100%" }}
                  />
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.modalClose()} size="lg">
                Cancel
              </Button>
              <Button variant="dark" size="lg" onClick={() => this.extend_trial()}>Extend Trial</Button>
            </Modal.Footer>
          </Modal>
          <div className="container">
            {this.state.show_alert && <Alert variant={this.state.varient}><div style={{ position: "relative", width: "100%" }}><span>{this.state.msg}</span> <span style={{ cursor: "pointer", position: "absolute", right: 5 }} onClick={() => { this.setState({ msg: "", show_alert: false, variant: "" }) }}>X</span></div></Alert>}
            <div className="table-responsive">
              <table
                id="datatable_admin"
                className="table-list cruxcompetitive-table table table-striped table-bordered"
              >
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Country</th>
                    <th>Name</th>
                    <th>Training</th>
                    <th>Consultation</th>
                    <th>Working As</th>
                    <th>Account Type</th>
                    <th>Professional Designation</th>
                    <th>Regstration Date</th>
                    <th>Initiative</th>
                    <th>Scorecard Name</th>
                    <th>Purpose</th>
                    <th>Version</th>
                    <th>Opt Score</th>
                    <th>Execution Score</th>
                    <th>Subscription</th>
                    <th>T & C</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {

                    this.state.data.map((result, i) => {
                      let id = "/user/initiative/" + result.evaluation_id;

                      let str = "";
                      if (result.situation === "1") {
                        str = "Evaluate an idea";
                      }
                      if (result.situation === "2") {
                        str = "Evaluating an investment opportunity";
                      }
                      if (result.situation === "3") {
                        str = "Evaluating exiting Product";
                      }
                      if (result.situation === "4") {
                        str = "Evaluating in development Product";
                      }

                      return (
                        <tr key={i}>
                          <td>{result.email}</td>
                          <td>{result.country}</td>
                          <td>{result.name}</td>
                          <td>{result.typefree ? result.typefree : "Not Checked"}</td>
                          <td>{result.typeconsultation ? result.typeconsultation : "Not checked"}</td>
                          <td>{result.working}</td>
                          <td>{result.account_type}</td>
                          <td>{result.designation}</td>
                          <td>{result.created_at}</td>
                          <td><Link to={id}>{result.initiative_name}</Link></td>
                          <td><Link to={"/user/results/" + result.evaluation_id}>{result.scorecard_name}</Link></td>
                          <td>{str}</td>
                          <td>{result.version}</td>
                          <td>{result.opt_score}</td>
                          <td>{result.execution_score}</td>
                          <td>{<Button variant={result.status == "A" ? "danger" : "success"} onClick={() => this.update_subscription(result.user_id, result.status == "A" ? "disable" : "enable")}>{result.status == "A" ? "Disable" : "Enable"}</Button>}</td>
                          {/* <td>{<Button variant={"primary"} onClick={()=>{this.modalShow(); this.setState({user_id:result.user_id})}}>Extend</Button>}</td> */}
                          <td>{result.terms == "N" ? "Not Accepted" : " Accepted"}</td>
                          <td>
                            <ul className="table-icon">
                              <li>
                                <button>
                                  <Link to={id} variant="primary">
                                    <i className="fa-solid fa-pencil" title="edit"></i>
                                  </Link>
                                </button>
                              </li>
                              {/* <li>
                              <button>
                                <i className="fa-solid fa-book"></i>
                              </button>
                            </li> */}
                              <li>
                                <button>
                                  <i className="fa-solid fa-cloud-arrow-down"></i>
                                </button>
                              </li>
                              <li>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={(e) =>
                                    this.delRow(result.evaluation_id)
                                  }
                                >
                                  <i className="fa-solid fa-trash-can"></i>
                                </button>
                              </li>
                              <Button variant={"dark"} onClick={() => { this.modalShow(); this.setState({ user_id: result.user_id }) }}>Extend</Button>
                            </ul>
                          </td>
                        </tr>
                      );
                    })

                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default AdminListComponent;
